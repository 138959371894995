import { memo } from 'react';

import { useTitleStyles } from './styles';

const Title = ({ value, isSingleColumn }) => {
  const { title } = useTitleStyles();

  return isSingleColumn ? (
    <h2 className={title}>{value}</h2>
  ) : (
    <h3 className={title}>{value}</h3>
  );
};

export default memo(Title);

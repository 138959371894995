import { memo } from 'react';
import { BsFillGeoAltFill } from 'react-icons/bs';

import { useLocationStyles } from '../styles';

import Location from './Location';

const CitiesListing = ({ cities }) => {
  const {
    dividerStyle,
    adCountStyle,
    locationWrapper,
    settlementStyle,
    locationIconStyle,
    settlementLetterStyle,
  } = useLocationStyles();

  const groupedCities = cities.reduce(
    (acc, { name, count, slug, province_code, is_popular }) => {
      const firstLetter = name[0].toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push({ name, slug, count, province_code, is_popular });

      return acc;
    },
    {},
  );

  return Object.entries(groupedCities).map(([letter, cities]) => (
    <div key={letter}>
      <li className={settlementLetterStyle}>{letter}</li>
      <li className={dividerStyle} />
      {cities?.map(({ name, count, slug, province_code, is_popular }) => {
        return (
          <Location
            key={name}
            slug={slug}
            name={name}
            adCount={count}
            locationKey={is_popular ? 'popular_city' : 'city'}
            province_code={province_code}
          >
            <BsFillGeoAltFill className={locationIconStyle} />
            <div>
              <div>
                <div className={locationWrapper}>
                  <span className={settlementStyle}>{name}</span>
                  <span className={adCountStyle}>{`${count} ads`}</span>
                </div>
              </div>
            </div>
          </Location>
        );
      })}
    </div>
  ));
};

export default memo(CitiesListing);

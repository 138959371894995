import { memo } from 'react';
import useMemoSelector from 'hooks/useMemoSelector';

import { deviceParams, getLanguage, getUrlStructure } from 'store/reselect';

import Title from './Title';
import Image from './Image';
import Button from './Button';
import Divider from './Divider';
import Description from './Description';
import Advertisement from './Advertisement';
import { useContentBlockStyles } from './styles';

const contentItems = {
  image: Image,
  title: Title,
  button: Button,
  divider: Divider,
  text: Description,
  description: Description,
  advertisement: Advertisement,
};

const ContentBlock = () => {
  const { root } = useContentBlockStyles();
  const { urlStructure, lang, deviceType } = useMemoSelector(store => ({
    lang: getLanguage(store),
    deviceType: deviceParams()(store).deviceType,
    urlStructure: getUrlStructure(store),
  }));

  const { content = {} } = urlStructure;

  return (
    <div className={root}>
      <div className="content">
        {content[lang]?.map(el => (
          <div
            className="content-block"
            key={`content-${el[0].id}`}
            style={{
              display:
                el.length > 1 || deviceType === 'mobile' ? 'flex' : 'block',
              ...(deviceType === 'mobile' && {
                flexDirection: 'column',
                alignItems: 'center',
              }),
            }}
          >
            {el
              .filter(
                ({ type, advertisement }) =>
                  type &&
                  (type !== 'advertisement' || !advertisement.isOffline),
              )
              .map(item => {
                const Component = contentItems[item.type];

                return (
                  <div style={{ flex: 1 }}>
                    <Component
                      key={item.id}
                      name={item.type}
                      variant={item.variant}
                      columnCount={el.length}
                      value={item[item.type]}
                      isSingleColumn={el.length === 1}
                      {...(item.type === 'button' && { type: item.type })}
                    />
                  </div>
                );
              })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(ContentBlock);

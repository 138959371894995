import { memo } from 'react';
import clsx from 'clsx';
import useMemoSelector from 'hooks/useMemoSelector';

import { deviceParams } from 'store/reselect';

import { useButtonStyles } from './styles';

const Button = ({ value, type }) => {
  const { deviceType } = useMemoSelector(deviceParams());
  const { root, link } = useButtonStyles();

  return (
    <div className={clsx({ [root]: deviceType === 'mobile' || type })}>
      <a
        className={clsx(link, value.variant)}
        href={value.url}
        target={value.target}
      >
        {value.text}
      </a>
    </div>
  );
};

export default memo(Button);

import { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import useDebounce from 'hooks/use-debounce';
import useMemoSelector from 'hooks/useMemoSelector';

import AsideMenu from 'Components/AsideMenu';

import { convertCapitalize } from 'utils/handlers';

import {
  deviceParams,
  getUrlStructure,
  getIsMobileSearchOpen,
  getSelectedSettlement,
} from 'store/reselect';
import { setSearchSuggestionsOpen, setSelectedProvince } from 'store/actions';

import API from 'services/api';

import { useStyles } from './styles';
import SearchWithSuggestions from './searchWithSuggestions';

const Search = ({ headerHeight }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const router = useRouter();
  const styles = useStyles();
  const { deviceType, selectedSettlement, isMobileSearchOpen, urlStructure } =
    useMemoSelector(store => ({
      deviceType: deviceParams()(store).deviceType,
      urlStructure: getUrlStructure(store),
      selectedSettlement: getSelectedSettlement(store),
      isMobileSearchOpen: getIsMobileSearchOpen(store),
    }));

  const [searchValue, setSearchValue] = useState();
  const [suggestions, setSuggestions] = useState();

  const [hasDistancesOption, setHasDistancesOption] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  const {
    term,
    isNearby,
    selectedCity,
    label_ids = [],
    selectedProvince,
    category_id: categoryId,
  } = urlStructure || {};

  useEffect(() => {
    (async () => {
      try {
        const withDistance = !!selectedCity || !!selectedProvince || !!isNearby;

        setHasDistancesOption(withDistance);

        const searchedValueFromUrl = selectedCity || selectedProvince;

        const nearbyText = isNearby ? t('nearby') : '';

        setSearchValue(
          convertCapitalize(searchedValueFromUrl) || term || nearbyText,
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, [isNearby, selectedCity, selectedProvince, t, term]);

  useEffect(() => {
    if (!router.query.slug?.length && searchValue) {
      setSearchValue('');
    }
  }, [router.query.slug]);

  const labelId = label_ids[0];

  useEffect(() => {
    if (!debouncedValue) {
      setSuggestions([]);
      return;
    }

    const getSuggest = async () => {
      try {
        const { data } = await API.fetchSearchSuggestions({
          term: debouncedValue,
          category_id: categoryId,
          label_id: labelId,
        });

        setSuggestions(data);
      } catch (error) {
        setSuggestions([]);
        console.error(error);
      }
    };

    getSuggest();
  }, [categoryId, debouncedValue, labelId]);

  useLayoutEffect(() => {
    if (isMobileSearchOpen) {
      document.body.classList.add('body--fixed__overflow--no-scroll');
    } else {
      document.body.classList.remove('body--fixed__overflow--no-scroll');
    }
  }, [isMobileSearchOpen]);

  const isMobile = deviceType === 'mobile';

  return (
    <div
      id="search-input-wrapper"
      className={clsx(styles['search-wrapper'], 'opened_mobile')}
    >
      {isMobileSearchOpen ? (
        <AsideMenu>
          <div className={styles['search-mobile']} id="search-aside-wrapper">
            <SearchWithSuggestions
              autoFocus
              isMobile={isMobile}
              searchValue={searchValue}
              suggestions={suggestions}
              headerHeight={headerHeight}
              setSearchValue={setSearchValue}
              setSuggestions={setSuggestions}
              hasDistancesOption={hasDistancesOption}
              isMobileSearchOpen={isMobileSearchOpen}
              onFocus={() => dispatch(setSearchSuggestionsOpen(true))}
              onClickOutside={() => {
                dispatch(setSelectedProvince(''));
                dispatch(setSearchSuggestionsOpen(false));
              }}
            />
          </div>
        </AsideMenu>
      ) : (
        <SearchWithSuggestions
          isMobile={isMobile}
          searchValue={searchValue}
          suggestions={suggestions}
          headerHeight={headerHeight}
          setSearchValue={setSearchValue}
          setSuggestions={setSuggestions}
          hasDistancesOption={hasDistancesOption}
          isMobileSearchOpen={isMobileSearchOpen}
          onFocus={() => dispatch(setSearchSuggestionsOpen(true))}
          onClickOutside={() => {
            dispatch(setSelectedProvince(''));
            dispatch(setSearchSuggestionsOpen(false));
          }}
        />
      )}
    </div>
  );
};

export default Search;

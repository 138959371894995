import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import useMemoSelector from 'hooks/useMemoSelector';

import { SimpleInputSSR } from 'Components/Shared/UI/SimpleInput';

import { getSelectedProvince, getIsMobileSearchOpen } from 'store/reselect';
import {
  setUrlStructure,
  setSelectedProvince,
  setMobileSearchOpen,
  setSearchSuggestionsOpen,
} from 'store/actions';

const SearchBar = forwardRef(
  (
    {
      onFocus,
      isSearch,
      isMobile,
      suggestions,
      searchValue,
      setSearchValue,
      setSuggestions,
      isSuggestionsOpen,
      hasDistancesOption,
      setShouldRemoveDistance,
    },
    ref,
  ) => {
    const router = useRouter();
    const t = useTranslations();
    const dispatch = useDispatch();

    const { selectedProvince, isMobileSearchOpen } = useMemoSelector(store => ({
      isMobileSearchOpen: getIsMobileSearchOpen(store),
      selectedProvince: getSelectedProvince(store),
    }));

    const updateRoute = useCallback(() => {
      const updatedSlug = router.query.slug.filter(segment => {
        return !segment
          .toLowerCase()
          .includes(searchValue.toLowerCase().replace(/ /g, '-'));
      });

      const newPathname = updatedSlug.join('/');

      if (
        router.query.settlement
          ?.toLowerCase()
          .includes(searchValue.toLowerCase().replace(/ /g, '-'))
      ) {
        delete router.query.settlement;
      } else if (
        router.query.province?.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        delete router.query.province;
      } else if (
        router.query.term?.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        delete router.query.term;
      }

      const { slug, ...redirectionQueries } = router.query;

      if (
        (!!updatedSlug.length || !!Object.keys(redirectionQueries).length) &&
        !updatedSlug.includes('search')
      ) {
        router.push({
          pathname: newPathname,
          query: redirectionQueries,
        });
      } else {
        dispatch(setUrlStructure({}));
        router.push('/');
      }
    }, [dispatch, router, searchValue]);

    const onSearchCancel = useCallback(() => {
      updateRoute();
      setSearchValue('');
      setShouldRemoveDistance(false);
    }, [setSearchValue, setShouldRemoveDistance, updateRoute]);

    const handleInputChange = useCallback(
      e => {
        if (suggestions.length) setSuggestions([]);
        if (!isSuggestionsOpen) dispatch(setSearchSuggestionsOpen(false));
        const trimmedValue = e.target.value.trimStart();
        setSearchValue(trimmedValue);
      },
      [
        dispatch,
        isSuggestionsOpen,
        setSearchValue,
        setSuggestions,
        suggestions?.length,
      ],
    );

    const handleOpenMobileSearch = useCallback(() => {
      isMobile && dispatch(setMobileSearchOpen(!isMobileSearchOpen));
    }, [dispatch, isMobile, isMobileSearchOpen]);

    const handleRemoveSelectedProvince = () => {
      if (selectedProvince) {
        dispatch(setSelectedProvince(''));
      }
    };

    useEffect(() => {
      const handleEsc = event => {
        if (event.key === 'Escape') {
          if (selectedProvince) {
            dispatch(setSelectedProvince(''));
            ref.current.focus();
            return;
          }

          if (isSuggestionsOpen) {
            dispatch(setSearchSuggestionsOpen(false));
            ref.current.blur();
            return;
          }

          if (searchValue) {
            setSearchValue('');
          }
        }
      };

      document.addEventListener('keydown', handleEsc);

      return () => {
        document.removeEventListener('keydown', handleEsc);
      };
    }, [
      ref,
      dispatch,
      searchValue,
      setSearchValue,
      selectedProvince,
      isSuggestionsOpen,
    ]);

    const withCancelIcon = useMemo(
      () =>
        (searchValue && !isMobile) ||
        (isMobile && searchValue && isMobileSearchOpen),
      [isMobile, isMobileSearchOpen, isSearch, searchValue],
    );

    return selectedProvince ? (
      <SimpleInputSSR
        disabled
        value={`Province ${selectedProvince}`}
        className="search-input"
        size="md"
        fullWidth
        name="name"
        type="text"
        icon={{
          iconLeft: isMobile || selectedProvince,
          onClick: handleRemoveSelectedProvince,
          className: 'icon-Back',
        }}
        customStylesInput={{
          width: '100%',
          fontSize: '14px',
          outline: 'none',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'center',
          borderRadius: '0px',
          textTransform: 'capitalize',
          backgroundColor: '#fff',
        }}
      />
    ) : (
      <SimpleInputSSR
        size="md"
        fullWidth
        name="name"
        type="text"
        onFocus={onFocus}
        maxLength="100"
        withoutCounter
        registerRef={ref}
        value={searchValue}
        className="search-input"
        customStylesDiv={{
          width: isMobile && hasDistancesOption ? '78%' : '100%',
        }}
        withCancelIcon={withCancelIcon}
        onCancel={onSearchCancel}
        placeholder={t('search_placeholder')}
        onMouseDown={() => {
          if (!isMobileSearchOpen) {
            handleOpenMobileSearch();
          }
        }}
        onChange={handleInputChange}
        icon={
          (isSuggestionsOpen || isMobile) && {
            iconLeft: true,
            onClick: handleOpenMobileSearch,
            className: isMobile
              ? isMobileSearchOpen
                ? 'icon-Back'
                : ''
              : 'icon-Search',
          }
        }
        customStylesInput={{
          width: !hasDistancesOption ? 'calc(100% - 1px)' : '100%',
          fontSize: '14px',
          outline: 'none',
          borderRadius: '0px',
        }}
      />
    );
  },
);

export default memo(SearchBar);
